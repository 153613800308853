import { InputValidationRule } from "vuetify"
import { required, isAbsoluteUrl } from "./Validation"

type Result = ReturnType<InputValidationRule>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputValidationFunction = (value: any) => Result

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorMessageFactoryFunction = (value: any) => string

type InputValidationFactoryFunction = (
  errorMessageFactory: ErrorMessageFactoryFunction
) => InputValidationFunction

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isString = (value: any): value is string => typeof value === "string"

export const createRequiredRule: InputValidationFactoryFunction = messageFactory => value =>
  (isString(value) && required(value) === true) || messageFactory(value)

export const createAbsoluteUrlRule: InputValidationFactoryFunction = messageFactory => value =>
  !value || (isString(value) && isAbsoluteUrl(value)) || messageFactory(value)
