



























import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  watch,
} from "@vue/composition-api"
import { TextSelection } from "prosemirror-state"
import { Editor, EditorContent } from "tiptap"
import MenuBar from "./MenuBar.vue"
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
} from "tiptap-extensions"
import TextColor from "@/components/textAreas/TextColor"
import Indent from "@/components/textAreas/Indent"
import Link from "@/components/textAreas/Link"

interface State {
  editor: typeof Editor | null
  errorMsg: string
}

export default defineComponent({
  props: {
    value: {
      type: [Object, String],
      default: {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MenuBar,
    EditorContent,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      errorMsg: "",
      editor: new Editor({
        editable: !props.readonly,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link({
            target: "_blank",
          }),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new TextColor(),
          new Indent(),
        ],

        content: "",
      }),
    })

    const setContenteWithoutHistory = (
      content: object,
      emitUpdate = false,
      parseOptions?: object
    ) => {
      const { doc, tr } = state.editor.state
      const document = state.editor.createDocument(content, parseOptions)
      const selection = TextSelection.create(doc, 0, doc.content.size)
      const transaction = tr
        .setSelection(selection)
        .replaceSelectionWith(document, false)
        .setMeta("addToHistory", false)
        .setMeta("preventUpdate", !emitUpdate)

      state.editor.view.dispatch(transaction)
    }

    const initState = (content: object) => {
      setContenteWithoutHistory(content)
      state.editor.setOptions({
        editable: !props.readonly,
      })
    }

    const transaction = () => {
      const html = state.editor.getHTML()
      if (props.required && html.match(/^<p>\s*<\/p>$/)) {
        state.errorMsg = "必須入力です"
      } else {
        state.errorMsg = ""
      }
      emit("input", html)
    }

    watch(
      () => props,
      newValue => {
        initState(newValue.value)
      },
      { deep: true }
    )

    onMounted(() => {
      initState(props.value)
      state.editor.on("transaction", transaction)
      transaction()
    })

    onUnmounted(() => {
      state.editor.off("transaction", transaction)
    })

    const focus = () => {
      state.editor.focus()
    }

    return {
      state,
      focus,
    }
  },
})
