














































































































































































































































import { computed, defineComponent, reactive, ref } from "@vue/composition-api"
import { Editor, EditorMenuBar } from "tiptap"
import BtnWithTooltip from "@/components/tooltips/BtnWithTooltip.vue"
import SetLinkModal from "./modals/SetLinkModal.vue"
import { isAbsoluteUrl } from "@/utilities/Validation"

export default defineComponent({
  components: { EditorMenuBar, BtnWithTooltip, SetLinkModal },
  props: {
    editor: {
      type: Editor,
      required: true,
    },
  },
  setup(props) {
    const editorValue = computed(() => props.editor)
    const colorMenu = ref(false)
    const swatchers = [
      "#000005",
      "#FF0000",
      "#00B050",
      "#0070C0",
      "#B0B0B0",
      "#FFA500",
      "#592088",
      "#FF69B4",
    ]
    const linkModalState = reactive({
      isOpen: false,
      input: {
        url: "",
      },
    })

    const getSelectedText = (): string => {
      const { selection, state } = editorValue.value
      const { from, to } = selection
      return state.doc.textBetween(from, to, " ")
    }

    const isTextSelected = () => {
      const { from, to } = editorValue.value.selection
      return from !== to
    }

    const changeColor = (
      command: { textcolor: (attrs: { color: string }) => void },
      v: string
    ) => {
      colorMenu.value = false
      command.textcolor({ color: v })
    }

    const increase = (
      command: {
        increase: () => void
        increaseList: () => void
      },
      isList: boolean
    ) => {
      if (isList) {
        command.increaseList()
      } else {
        command.increase()
      }
    }

    const decrease = (
      command: {
        decrease: () => void
        decreaseList: () => void
      },
      isList: boolean
    ) => {
      if (isList) {
        command.decreaseList()
      } else {
        command.decrease()
      }
    }

    const setLink = (
      command: {
        link: (options: { href: string | null }) => void
      },
      url?: string
    ) => {
      if (url) {
        command.link({ href: url })
      } else {
        command.link({ href: null })
      }
    }

    const openLinkModal = (input: typeof linkModalState.input) => {
      linkModalState.input = input
      linkModalState.isOpen = true
    }

    const closeLinkModal = () => {
      linkModalState.input = {
        url: "",
      }
      linkModalState.isOpen = false
    }

    const onLinkBtnClick = (currentUrl: string) => {
      const modalInput: typeof linkModalState.input = {
        url: currentUrl,
      }

      if (!modalInput.url) {
        const selectedText = getSelectedText()
        if (isAbsoluteUrl(selectedText) === true) {
          modalInput.url = selectedText
        }
      }

      openLinkModal(modalInput)
    }

    return {
      editorValue,
      colorMenu,
      swatchers,
      linkModalState,
      isTextSelected,
      changeColor,
      increase,
      decrease,
      setLink,
      onLinkBtnClick,
      closeLinkModal,
    }
  },
})
