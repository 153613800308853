import { EditorView } from "prosemirror-view"
import { Plugin } from "tiptap"
import { Link as LinkBase } from "tiptap-extensions"
import { getMarkAttrs } from "tiptap-utils"

export interface LinkOptions {
  target: string | null
}

export default class Link extends LinkBase {
  constructor(options: LinkOptions) {
    super(options)
  }

  get defaultOptions(): LinkOptions {
    return {
      target: null,
    }
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            mouseup: (view: EditorView, event: MouseEvent): boolean => {
              const { from, to } = this.editor.selection
              const isSelection = from !== to
              if (isSelection) return false

              const { schema } = view.state
              const attrs = getMarkAttrs(view.state, schema.marks.link)
              if (!attrs.href) return false

              const eventPath = event.composedPath()
              const docIndex = eventPath.findIndex(e => e === view.dom)
              const pathToEditor = eventPath.slice(0, docIndex + 1)

              if (!pathToEditor.find(n => n instanceof HTMLAnchorElement))
                return false

              event.stopPropagation()
              event.preventDefault()
              window.open(attrs.href, attrs.target)
              return true
            },
          },
        },
      }),
    ]
  }
}
