
















































































import { defineComponent, reactive, ref, watch } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import {
  createAbsoluteUrlRule,
  createRequiredRule,
} from "@/utilities/InputValidationRule"
import { InputValidationRule } from "vuetify"
import { i18nContainer } from "@/containers/I18nContainer"

interface FormInput<T> {
  value: T
  rules?: Array<InputValidationRule>
}

interface FormState {
  isValid: boolean
  inputs: {
    url: FormInput<string>
  }
}

export default defineComponent({
  props: {
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    url: {
      type: String,
      default: "",
    },
    clearEnabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextField,
    I18nFormattedMessage,
    DefaultButton,
    IconButton,
  },
  setup(props, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()
    const form = ref<HTMLFormElement | null>(null)
    const formState = reactive<FormState>({
      isValid: true,
      inputs: {
        url: {
          value: "",
          rules: [
            createRequiredRule(() =>
              formatMessage({
                id: "SetLinkModal.requiredRule",
                defaultMessage: "必須入力です",
              })
            ),
            createAbsoluteUrlRule(() =>
              formatMessage({
                id: "SetLinkModal.urlRule",
                defaultMessage: "URLを入力してください",
              })
            ),
          ],
        },
      },
    })

    const onOk = async () => {
      const { url } = formState.inputs
      const output = { url: url.value }
      emit("ok", output)
    }

    const onCancel = () => {
      emit("cancel")
    }

    const onClear = () => {
      emit("clear")
    }

    const resetForm = () => {
      formState.inputs.url.value = ""
      if (form.value) {
        form.value.reset()
      }
    }

    const setFormInputsFromProps = () => {
      const { url } = formState.inputs
      url.value = props.url || ""
    }

    watch(
      () => props.isOpenDialog,
      newValue => {
        if (!newValue) {
          resetForm()
        } else {
          setFormInputsFromProps()
        }
      }
    )

    watch(
      () => props.url,
      newValue => {
        formState.inputs.url.value = newValue || ""
      }
    )

    return {
      form,
      formState,
      onOk,
      onCancel,
      onClear,
    }
  },
})
