/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node } from "tiptap"
import { sinkListItem, liftListItem } from "prosemirror-schema-list"
import { wrapIn, lift } from "prosemirror-commands"

export default class Indent extends Node {
  get name() {
    return "indent_content"
  }

  get schema() {
    return {
      content: "block*",
      group: "block",
      defining: true,
      draggable: false,
      parseDOM: [
        {
          tag: "div",
          style: "margin-left",
        },
      ],
      toDOM: () => {
        return [
          "div",
          {
            style: "margin-left:20px",
          },
          0,
        ]
      },
    }
  }

  commands({ type, schema }: any) {
    return {
      increase: () => {
        return (state: any, dispatch: any) => {
          return wrapIn(type)(state, dispatch)
        }
      },
      decrease: () => {
        return (state: any, dispatch: any) => {
          return lift(state, dispatch)
        }
      },
      increaseList: () => sinkListItem(schema.nodes["list_item"]),
      decreaseList: () => liftListItem(schema.nodes["list_item"]),
    }
  }

  keys() {
    return {
      Tab: () => {
        const isList =
          this.editor.isActive.ordered_list() ||
          this.editor.isActive.bullet_list()
        if (isList) {
          return this.editor.commands.increaseList()
        } else {
          return this.editor.commands.increase()
        }
      },
      "Shift-Tab": () => {
        const isList =
          this.editor.isActive.ordered_list() ||
          this.editor.isActive.bullet_list()
        if (isList) {
          return this.editor.commands.decreaseList()
        } else {
          return this.editor.commands.decrease()
        }
      },
    }
  }
}
