/* eslint-disable @typescript-eslint/no-explicit-any */
import { Mark } from "tiptap"
import { updateMark } from "tiptap-commands"

export default class TextColor extends Mark {
  get name() {
    return "textcolor"
  }

  get schema() {
    return {
      attrs: {
        color: {
          default: "#000005",
        },
      },
      parseDOM: [
        {
          style: "color",
          getAttrs: (color: string) => {
            return {
              color: color || "#000005",
            }
          },
        },
      ],
      toDOM: (node: any) => {
        return [
          "span",
          {
            style: `color:${node.attrs.color}`,
          },
          0,
        ]
      },
    }
  }

  commands({ type }: any) {
    return (attrs: any) => {
      return updateMark(type, attrs)
    }
  }
}
