<template>
  <v-tooltip bottom open-delay="700">
    <template v-slot:activator="{ on }">
      <v-btn
        class="border-btn"
        :color="color"
        :data-id="customName"
        :disabled="disabled"
        icon
        v-on="parentOn || on"
        @click="() => $emit('click')"
      >
        <v-icon v-if="iconName">{{ iconName }}</v-icon>
        <span v-else>{{ text }}</span>
      </v-btn>
    </template>
    <span><slot /></span>
  </v-tooltip>
</template>

<script>
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  props: {
    customName: {
      type: String,
      default: "not_specified",
    },
    parentOn: {
      type: Object,
      requred: false,
    },
    color: String,
    iconName: String,
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
// .border-btn {
//   border: thin solid rgba(0, 0, 0, 0.2);
//   margin-right: 3px;
// }
</style>
